.app {
  display: flex;
  overflow: hidden;
  flex-direction: row;
}

.mainWithoutSidebar {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f2f2;
}

.mainWithoutSidebar > *:first-child {
  height: calc(100% - 207.95px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding-left: 345px;
}

.mainContent {
  padding-right: 2.5rem;
  height: 100%;
}

.footerContainer {
  margin-left: -1.5rem;
}
